<template>
    <div id="index">
        <div class="container">
            <div class="top_text">
                <p>你的位置: <span @click="goBack">首页</span> /<span @click="goPoliciesList">公告列表</span>/ 公告详情</p>
            </div>
            <div class="content_individual">
                <div class="content_details hide-scrollbar">
                    <div>{{ form.title }}</div>
                    <div>{{ form.createtime }}</div>
                    <div v-html="content"></div>
                </div>

            </div>
            <div class="bottom_text">{{ $store.state.text }}</div>
        </div>
    </div>
</template>

<script>
export default {
    name: "noticeDetails",
    data() {
        return {
            form: {},
            content: ''
        };
    },
    computed: {
    },
    watch: {
    },
    async mounted() {
        this.form = this.$route.query
        let node = ""
        node = this.form.content.replace(/<table/g, '<table class="table"')
        node = node.replace(/<tr/g, '<tr class="tr"')
        node = node.replace(/<td/g, '<td class="td"')
        node = node.replace(/<img src="/g, '<img src="' + this.$axios.defaults.baseURL + '/')
        this.content = node
        // this.$store.commit('settab', 2)
    },
    methods: {
        goBack(item) {
            this.$router.push({
                name: 'Index'
            })
        },
        goPoliciesList() {
            this.$router.push({
                name: 'noticeList',
            })
        },
    },
}
</script>

<style scoped lang="scss">
#index {
    //width: 100%;
    //height: 100vh;
    background: #434655;
}

.hide-scrollbar {
    -ms-overflow-style: none;
    /* IE 和 Edge */
    scrollbar-width: none;
    /* Firefox */

    /* 兼容 Chrome, Safari 和 Opera */
    ::-webkit-scrollbar {
        display: none;
    }
}

.content_individual {
    width: 100%;
    height: calc(100% - 10%);
    overflow: hidden;
    overflow-y: auto;
    background: #434655;

    &::-webkit-scrollbar {
        display: none;
    }

    .content_details {
        width: 100%;
        height: 100%;
        overflow: hidden;
        overflow-y: auto;
        background: #FFFFFF;
        padding: 0.45rem 0.2rem 0.3rem 0.2rem;
        border-radius: 0.04rem;
        font-size: 0.16rem;
        font-weight: 400;

        >div:nth-child(1) {
            font-size: 0.32rem;
            font-weight: 500;
            color: #071929;
            line-height: 0.45rem;
            text-align: center;
            margin-bottom: 0.25rem;
        }

        >div:nth-child(2) {
            color: #333333;
            line-height: 0.16rem;
            text-align: center;
            margin-bottom: 0.25rem;
        }

        >div:nth-child(2) {
            color: #6B7177;
            line-height: 0.25rem;
        }
    }
}

.bottom_text {
    font-size: 0.14rem;
    font-weight: 500;
    color: #999999;
    text-align: center;
    margin-top: 0.5rem;
}

.container {
    width: 90%;
    margin: 0 auto;
    height: calc(100vh - 1.66rem);

}

.top_text {
    font-size: 0.14rem;
    font-weight: 400;
    color: #FFFFFF;
    margin-top: 0.5rem;
    margin-bottom: 0.3rem;
    line-height: 0.2rem;
}
</style>
